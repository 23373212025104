import request from "@/utils/request";  //导入封装请求的js文件
// 登录
export function loginUser (data) {
  return request({
    url: '/userApi/user/login',
    method: 'post',
    headers: {
      //正式
      'authorization': 'Basic c2hhbmdmYW5nOnNoYW5nZmFuZy1zZWNyZXQ='
      //测试
      // 'Authorization':'Basic d2VzcGFjZTp3ZXNwYWNlLXNlY3JldA=='
    },
    params: data
  })
}
// 找回密码
export function logonUser (data) {
  return request({
    url: '/userApi/user/v2/update/password',
    method: 'post',
    data: data
  })
}
// 注册-提交
export function retrieveSub (data) {
  return request({
    url: '/userApi/user/login/register',
    method: 'post',
    data: data
  })
}
// 发送验证码
export function sendCode (query) {
  return request({
    url: '/userApi/user/getMobileVerificationCode',
    method: 'get',
    params: query,
  })
}
// 发送验证码
export function getMobileCodeByType (data) {
  return request({
    url: '/userApi/user/getMobileCodeByType/v1',
    method: 'post',
    data: data,
  })
}
//退出登录
export function postLogout (data) {
  return request({
    url: '/userApi/user/logout',
    method: 'post',
    data: data
  })
}