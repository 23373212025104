/* eslint-disable no-unused-vars */
import axios from "axios"
import qs from 'qs'
//axios.defaults.withCredentials=true;//让ajax携带cookie
// axios.defaults.withCredentials = true;
// axios.defaults.crossDomain=true
//import store from '@/store'//引入store
//import router from '@/router'//引入store

// eslint-disable-next-line no-unused-vars
let myVue;

//let SERVICE_URL = ''
//let FILE_URL = ''


/* eslint-disable */
// if (process.env.NODE_ENV === 'development') {
//   /* 开发环境 */
//   SERVICE_URL = 'http://'
// } else if (process.env.SERVICE_ENV === 'test') {
//   // 测试环境
//   SERVICE_URL = 'http://'
// } else {
//   SERVICE_URL = 'http://'
// }

// const httpAxios = axios.create({
//   timeout:20000
// })
const httpAxios = axios.create()
// httpAxios.defaults.timeout = 20000
//请求拦截
httpAxios.interceptors.request.use(function (config) {
  if (Object.prototype.toString.call(config.data) != '[object FormData]') {
    if (config.data && config.data.indexOf('qrTimeOut') !== -1) {
      let subStr = 'qrTimeOut=';
      let arr = config.data.split(subStr);
      config.timeout = null;
      config.data = arr[0];
    } else {
      config.timeout = 20000;
    }
  } else {
    config.timeout = 20000;
  }


  // if(config.url == 'https://cms.wespace.cn//news/findWorksNews'){
  //   config.headers = {
  //     usn:'gsaduias123'
  //   }
  // }
  // 在发送请求之前做些什么，例如加入token
  //let token = window.localStorage.getItem('userToken')
  // let token = null
  // //console.log(config);
  // //console.log(token);

  // if(token&&config.url.indexOf('')){
  //   config.headers.common['token'] = token
  // }

  //console.log("token:"+token)
  // //console.log(config);


  return config;
}, (error) => {
  //console.log("全局："+error);

  // 对请求错误做些什么
  return Promise.reject(error);
});


// 响应拦截
httpAxios.interceptors.response.use(response => {
  // //console.log('response',response);

  return response;
}, error => {
  //console.log(error);
  //console.log(error.response);
  //console.log(JSON.parse(JSON.stringify(error)));

  if (error.response) {
    if (error.response.code === '401') {

      localStorage.removeItem('userToken');

      // router.push({
      //   name:'login'
      // })
    }
    switch (error.response.status) {
      // 返回401，清除token信息并跳转到登录页面
      case 401:
        localStorage.removeItem('userToken');
      // router.push({
      //   name:'login'
      // })

    }
    // 返回接口返回的错误信息
    return Promise.reject(error.response);
  }
});




// POST
const axiosPost = function (url = '', data = {}) {
  let {
    sucFun,
    errFun
  } = XHR()
  let reqData = qs.stringify({
    ...data
  })


  return httpAxios.post(url, reqData).then(sucFun).catch(errFun)
}


// GET
const axiosGet = function (url = '', data = {}) {
  let {
    sucFun,
    errFun
  } = XHR()
  let params = {
    params: {
      ...data
    }
  }

  return httpAxios.get(url, params).then(sucFun).catch(errFun)
}


const axiosFormData = function (url = '', data = {}) {
  let {
    sucFun,
    errFun
  } = XHR()

  // let reqData = qs.stringify({
  //   ...user,
  //   ...data
  // })
  let reqData = data;

  let config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  };

  return httpAxios.post(url, reqData, config).then(sucFun).catch(errFun)

}

const XHR = () => {
  // if (!window.sessionStorage.token) router.replace('/')
  // 用户登录信息
  // const user = {
  //   token: window.sessionStorage.token,
  //   system: 'b'
  // }

  const sucFun = res => {
    return res.data
  }

  const errFun = (res) => {
    // let r = {
    //   msg: '请求失败,请稍后重试！'
    // }
    return res
  }

  return {
    // user,
    sucFun,
    errFun
  }
}

export default (Vue) => {
  myVue = Vue
  Vue.prototype.$axios = httpAxios
  Vue.prototype.$axiosGet = axiosGet
  Vue.prototype.$axiosPost = axiosPost
  Vue.prototype.$axiosFormData = axiosFormData
  // Vue.prototype.$baseApi = SERVICE_URL
  // Vue.prototype.$WsApi = WEBSOCKET_URL
  Vue.axios = httpAxios
  Vue.axiosPost = axiosPost
  Vue.axiosGet = axiosGet
  Vue.axiosFormData = axiosFormData
}
export {
  axiosGet,
  axiosPost,
  axiosFormData
}
