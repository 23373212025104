<template>
  <div>
    <div class="caaeg" v-if="flag">
      <div class="topNav">
      <div class="navTag">
        <p class="title" @click="$router.push({path:'/'})" v-if="linkItem == 0"> </p>
        <p class="titlel" @click="$router.push({path:'/'})" v-if="linkItem == 1"></p>
        <!-- <img src="../assets/logo@2x.png" alt="" @click="$router.push({path:'/'})" /> -->
        <div class="nvaList" v-for="(item,index) in navList" :key="index">
          <p class="nav" :class="activeCode === item.path ? 'active': ''" v-if="!item.children.length" @click="toChildren(item)">{{item.label}}</p>
          <p v-else class="nav">
            <el-dropdown @command="handleNav" placement="top-start" active-text-color="#004AFF">
              <span class="el-dropdown-link">
                {{item.label}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="pl in item.children" :key="pl.code" :command="pl.code">{{pl.label}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </p>
        </div>
      </div>
      <div class="navBtn">
        <el-button v-if="!usnInfo" type="primary" @click="tologin">登录/注册</el-button>
        <el-dropdown v-else trigger="click" @command="handleCommand">
          <span class="el-dropdown-link" style="whiteSpace: nowrap">
            <img style="borderRadius: 50%" :src="usnurl?(usnurl.substring(0, 4)==='http'?usnurl:fileUrl+usnurl):require('../assets/account/headimg.png')" alt="">
            {{usnName}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" >
            <el-dropdown-item command="account">我的账号</el-dropdown-item>
            <el-dropdown-item command="password">修改密码</el-dropdown-item>
            <el-dropdown-item command="exitLogin">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    </div>
    <router-link to="/home"></router-link>
    <router-link to="/"></router-link>
    <router-link to="/registerList"></router-link>
    <router-link to="/transferenceList"></router-link>
    <router-link to="/login"></router-link>
    <router-link to="/logon"></router-link>
    <router-link to="/account"></router-link>
    <router-link to="/assetIssuance"></router-link>
    <router-link to="/solution"></router-link>
    <router-view></router-view>
    <my-footer  v-if="$route.meta.shovalues"/>
  </div>
</template>

<script>
import MyFooter from '../views/introduce/footer.vue'
import { postLogout } from '@/api/login.js';
  export default {
    components: {
      MyFooter
    },
    data(){
      return{
        flag: true,
        linkItem: 0,
        navList:[{
          code: '/',
          label: '首页',
          path: '/',
          children: []
        },
        // linkItem: 0,
        // {
        //   code: '1',
        //   label: '申请节点',
        //   path: '',
        //   children: []
        // },
        // {
        //   code: '2',
        //   label: '城市节点',
        //   path: '',
        //   children: []
        // },
        // {
        //   code: '1',
        //   // label: '版权存证',
        //   label: '作品存证',
        //   path: '/exitEvidence',
        //   children: []
        // },
        {
          code: '2',
          // label: '版权预登记',
          label: '作品存证',
          path: '/pre-registration',
          children: []
        },
        {
          code: '102',
          label: '版权注册',
          path: '/copyrighForm/home',
          children: []
        },
        {
          code: '3',
          label: '版权交易',
          path: '',
          children: [
            {
              code: '5',
              label: '版权转让',
              path: '/transferenceList',
              children: []
            },{
              code: '6',
              label: '版权授权',
              path: '/registerList',
              children: []
            },
          ]
        },
        // {
        //   code: '7',
        //   label: '版权存证',
        //   path: '/exitEvidence',
        //   children: []
        // },
        // {
        //   code: '4',
        //   label: '版权注册',
        //   path: '/home',
        //   children: []
        // },{
        //   code: '6',
        //   label: '版权转让',
        //   path: '/transferenceList',
        //   children: []
        // },{
        //   code: '3',
        //   label: '版权授权',
        //   path: '/registerList',
        //   children: []
        // },
        {
          code: '7',
          label: '版权发行',
          path: '/assetIssuance',
          children: []
        },
        {
            code: '8',
            label: '资产管理',
            path: '/assetManagement',
            children: []
        },
        {
          code: '11',
          label: '解决方案',
          path: '',
          children: [
            {
              code: '12',
              label: '物权注册',
              path: '/solution/arTregistration',
              children: []
            },
            // {
            //   code: '13',
            //   label: '活动管理',
            //   path: '',
            //   children: []
            // },
            {
              code: '14',
              label: '图书管理',
              path: '/book/newbookRelease',
              children: []
            },
            {
              code: '20',
              label: '活动管理',
              path: '/activity/activityList',
              children: []
            },
            {
              code: '21',
              label: '文档管理',
              path: '/document',
              children: []
            },
            {
              code: '22',
              label: '3D模型预览',
              path: '/3dModelView',
              children: []
            },
          ]
        },
        {
          code: '9',
          label: '可信版权链',
          path: '',
          children: [
            {
              code: 'licenseinfo',
              label: '版权链查证',
              path: 'https://www.licenseinfo.cn/'
            },
            {
              code: 'bjxwcbj',
              label: '北京市版权局区块链查证平台',
              path: 'https://szzs.bjxwcbj.gov.cn/'
            },
            {
              code: 'bjinternetcourt',
              label: '北京互联网法院天平链查证',
              path: 'https://tpl.bjinternetcourt.gov.cn/index.html#/pcSearch'
            },
            {
              code: '10',
              label: '节点接入申请',
              path: '/nodeAccess'
            },
          ]
        }
],
        activeCode: '/',
        usnInfo: '',
        usnName: '',
        usnurl: '',
        fileUrl: 'https://files.wespace.cn/'
      }
    },
    created(){
      let routers = this.$router.options.routes
      let hash = this.$route.path
      this.flag = routers[0].children.some(val => val.path  == ('/' + hash.split('/')[1]))
      // this.flag = routers[0].children.some(val => val.path  == ('/' + hash.split('/')[1]))
      if (this.$route.query.usn) {
        sessionStorage.setItem('usn', this.$route.query.usn)
        // let usnInfo = sessionStorage.getItem('usnInfo')?JSON.parse(sessionStorage.getItem('usnInfo')) : ''
        // if(usnInfo.user.postcode != "100"||usnInfo.user.postcode == ''){
        //   this.navList =[{
        //     code: '/',
        //     label: '首页',
        //     path: '/',
        //     children: []
        //   },
        //   // {
        //   //   code: '1',
        //   //   label: '申请节点',
        //   //   path: '',
        //   //   children: []
        //   // },
        //   // {
        //   //   code: '2',
        //   //   label: '城市节点',
        //   //   path: '',
        //   //   children: []
        //   // },
        //   {
        //     code: '1',
        //     label: '版权存证',
        //     path: '/exitEvidence',
        //     children: []
        //   },
        //   {
        //     code: '2',
        //     label: '版权注册',
        //     path: '/home',
        //     children: []
        //   },
        //   {
        //     code: '3',
        //     label: '版权交易',
        //     path: '',
        //     children: [
        //       {
        //         code: '5',
        //         label: '版权转让',
        //         path: '/transferenceList',
        //         children: []
        //       },{
        //         code: '6',
        //         label: '版权授权',
        //         path: '/registerList',
        //         children: []
        //       },
        //     ]
        //   },
        //   // {
        //   //   code: '7',
        //   //   label: '版权存证',
        //   //   path: '/exitEvidence',
        //   //   children: []
        //   // },
        //   // {
        //   //   code: '4',
        //   //   label: '版权注册',
        //   //   path: '/home',
        //   //   children: []
        //   // },{
        //   //   code: '6',
        //   //   label: '版权转让',
        //   //   path: '/transferenceList',
        //   //   children: []
        //   // },{
        //   //   code: '3',
        //   //   label: '版权授权',
        //   //   path: '/registerList',
        //   //   children: []
        //   // },
        //   {
        //     code: '7',
        //     label: '版权发行',
        //     path: '/assetIssuance',
        //     children: []
        //   },
        //   {
        //       code: '8',
        //       label: '资产管理',
        //       path: '/assetManagement',
        //       children: []
        //   },
        //   {
        //     code: '9',
        //     label: '可信版权链',
        //     path: '',
        //     children: [
        //       {
        //         code: 'licenseinfo',
        //         label: '版权链查证',
        //         path: 'https://www.licenseinfo.cn/'
        //       },
        //       {
        //         code: 'bjxwcbj',
        //         label: '北京市版权局区块链查证平台',
        //         path: 'https://szzs.bjxwcbj.gov.cn/'
        //       },
        //       {
        //         code: 'bjinternetcourt',
        //         label: '北京互联网法院天平链查证',
        //         path: 'https://tpl.bjinternetcourt.gov.cn/index.html#/pcSearch'
        //       },

        //     ]
        //   }]
        // }
      }
      if (sessionStorage.getItem('activePath')) {
        this.activeCode = sessionStorage.getItem('activePath')
      }
      if(sessionStorage.getItem('usn')){
        this.usnInfo = sessionStorage.getItem('usnInfo')?JSON.parse(sessionStorage.getItem('usnInfo')) : ''
        if(this.usnInfo.user.postcode != "100"||this.usnInfo.user.postcode == ''){
          this.navList =[{
            code: '/',
            label: '首页',
            path: '/',
            children: []
          },
          // {
          //   code: '1',
          //   label: '申请节点',
          //   path: '',
          //   children: []
          // },
          // {
          //   code: '2',
          //   label: '城市节点',
          //   path: '',
          //   children: []
          // },
          // {
          //   code: '1',
          //   // label: '版权存证',
          //   label: '作品存证',
          //   path: '/exitEvidence',
          //   children: []
          // },
          {
            code: '2',
            label: '作品存证',
            path: '/pre-registration',
            children: []
          },
          {
            code: '102',
            label: '版权注册',
            path: '/copyrighForm/home',
            children: []
          },
          {
            code: '3',
            label: '版权交易',
            path: '',
            children: [
              {
                code: '5',
                label: '版权转让',
                path: '/transferenceList',
                children: []
              },{
                code: '6',
                label: '版权授权',
                path: '/registerList',
                children: []
              },
            ]
          },
          // {
          //   code: '7',
          //   label: '版权存证',
          //   path: '/exitEvidence',
          //   children: []
          // },
          // {
          //   code: '4',
          //   label: '版权注册',
          //   path: '/home',
          //   children: []
          // },{
          //   code: '6',
          //   label: '版权转让',
          //   path: '/transferenceList',
          //   children: []
          // },{
          //   code: '3',
          //   label: '版权授权',
          //   path: '/registerList',
          //   children: []
          // },
          {
            code: '7',
            label: '版权发行',
            path: '/assetIssuance',
            children: []
          },
          {
              code: '8',
              label: '资产管理',
              path: '/assetManagement',
              children: []
          },
          {
            code: '11',
            label: '解决方案',
            path: '',
            children: [
              {
                code: '12',
                label: '物权注册',
                path: '/solution/arTregistration',
                children: []
              },{
              //   code: '13',
              //   label: '活动管理',
              //   path: '',
              //   children: []
              // },{
                code: '14',
                label: '图书管理',
                path: '/book/newbookRelease',
                children: []
              },{
                code: '20',
                label: '活动管理',
                path: '/activity/activityList',
                children: []
              },
              {
                code: '21',
                label: '文档管理',
                path: '/document',
                children: []
              },
              {
                code: '22',
                label: '3D模型预览',
                path: '/3dModelView',
                children: []
              },
            ]
          },
          {
            code: '9',
            label: '可信版权链',
            path: '',
            children: [
              {
                code: 'licenseinfo',
                label: '版权链查证',
                path: 'https://www.licenseinfo.cn/'
              },
              {
                code: 'bjxwcbj',
                label: '北京市版权局区块链查证平台',
                path: 'https://szzs.bjxwcbj.gov.cn/'
              },
              {
                code: 'bjinternetcourt',
                label: '北京互联网法院天平链查证',
                path: 'https://tpl.bjinternetcourt.gov.cn/index.html#/pcSearch'
              },

            ]
          }]
        }
      }
      let host = window.location.host
      if (host == 'www.wespace.cn') {
        this.linkItem =  0
      }  else if(host == 'm.ccibs.cn') {
        this.linkItem = 1
      }
    },
    watch: {
      // usnInfo:{
      //   handler(newval, oldval) {
      //     if (newval) {
      //       let name
      //       let url
      //       if (this.usnInfo.user) {
      //         name = this.usnInfo.user.truename || this.usnInfo.user.nickname || this.usnInfo.user.username
      //         url = this.usnInfo.user.headimg
      //       } else {
      //         name = this.usnInfo.truename || this.usnInfo.nickname || this.usnInfo.username
      //         url = this.usnInfo.headimg || ''
      //       }
      //       this.usnurl = url
      //       this.usnName = name.length > 11 ? name.substring(0, 10) + '...' : name
      //     }
      //   },
      //   deep: true
      // },
      '$store.state.userData': {
        handler(newval,oldval) {
          if (Object.keys(newval).length) {
            this.usnInfo = newval
            name = newval.truename || newval.nickname || newval.username
            this.usnurl = newval.headimg || ''
            this.usnName = name.length > 11 ? name.substring(0, 10) + '...' : name
          } else {
            this.usnInfo = ''
          }
        },
        deep: true,
        immediate: true
      },
      '$route.path':{
        handler(newval, oldval) {
          let path = newval.split('/')[1]
          console.log(this.navList.filter(val => val.path.includes(path)), 'path')
          if (this.navList.filter(val => val.path.includes(path)).length) {
            this.activeCode = this.navList.filter(val => val.path.includes(path))[0].path
          }
        }
      }
    },
    methods:{
      tologin(){
        this.activeCode = '/login'
        this.$router.push({
          path: '/login'
        })
      },
      toChildren(item){
        console.log("item",item)
        if (item.path === this.activeCode) {
          return
        }
        if (!item.path && !item.children.length) {
          this.$message('功能暂未开发')
          return
        }
        if (!Object.keys(this.$store.state.userData).length && item.path !== '/' && item.path !== '/pre-registration' && item.path !== '/exitEvidence'&& item.path !== '/document') {
          console.log("item.path",item.path)
          this.activeCode = item.path
          this.$message({
            message: '请先登录',
            duration: 1000
          })
          this.$router.push({
            path: '/login',
            query: {
              redirect: item.path
            }
          })
          return
        }
        if (item.path) {
          console.log("item.path",item.path)
          this.activeCode = item.path
          sessionStorage.setItem('activePath', item.path)
          this.$router.push({
            path: item.path
          })
        } else if (!item.children.length) {
          this.$message('功能暂未开发')
        }
      },
      handleCommand(command){
        switch (command) {
          case 'exitLogin':
          let that = this
          this.$confirm('是否要退出该账号', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            postLogout().then(res => {
              if(res.code == 200){
                that.exitLogin()
                this.$store.commit({
                  type: "setUserToken",
                  userToken: ""
                })
                this.$store.commit({
                  type: "setUserData",
                  userData: ""
                })
                this.$store.commit({
                  type: "setUnreadMessage",
                  unreadMessage: "0"
                })
                this.$message({
                  type: "success",
                  message: res.message
                });
              }
            })
            // that.exitLogin()
            // this.$store.commit({
            //   type: "setUserToken",
            //   userToken: ""
            // })
            // this.$store.commit({
            //   type: "setUserData",
            //   userData: ""
            // })
            // this.$store.commit({
            //   type: "setUnreadMessage",
            //   unreadMessage: "0"
            // })
          }).catch(()=>{})
          break;
          case 'account':
          if (this.activeCode === '/account') {
            break
          }
          this.activeCode = '/account'
          this.$router.push({
            path: '/account'
          })
          break;
          case 'password':
          this.activeCode = '/login'
          this.$router.push({
            path: '/login',
            query: {
              sonValue: 2,
              showLogin: 0
            }
          })
          break;
        }
      },
      exitLogin(){
        sessionStorage.removeItem('usn')
        sessionStorage.removeItem('usn-token')
        sessionStorage.removeItem('usnInfo')
        this.usnInfo = ''
        this.$router.push({
          path: '/login'
        })
      },
      handleNav(command){
        sessionStorage.setItem('activePath', command)
        switch (command) {
          case 'licenseinfo':
          window.open('https://www.licenseinfo.cn/', '_blank')
          break;
          case 'bjxwcbj':
          window.open('https://szzs.bjxwcbj.gov.cn/', '_blank')
          break;
          case 'bjinternetcourt':
          window.open('https://tpl.bjinternetcourt.gov.cn/index.html#/pcSearch', '_blank')
          break;
          case '5':
          this.$router.push({
            name: 'transferenceList',
          })
          this.activeCode = '/transferenceList'
          break;
          case '6':
          this.$router.push({
            name: 'registerList',
          })
          this.activeCode = '/registerList'
          break;
          case '10':
          this.$router.push({
            name: 'nodeAccess',
          })
          this.activeCode = '/nodeAccess'
          break;
          case '12':
          this.$router.push({
            path: '/solution/arTregistration',
          })
          this.activeCode = '/solution/arTregistration'
          break;
          case '14':
          this.$router.push({
            path: '/book/newbookRelease',
          })
          this.activeCode = '/book/newbookRelease'
          break;
          case '20':
          // this.$message('功能暂未开放')
          this.$router.push({
            path: '/activity/activityList',
          })
          this.activeCode = '/activity/activityList'
          break;
          case '21':
            // this.$message('功能暂未开放')
            this.$router.push({
              path: '/document',
            })
            this.activeCode = '/document'
            break;
            case '22':
            // this.$message('功能暂未开放')
            this.$router.push({
              path: '/3dModelView',
            })
            this.activeCode = '/3dModelView'
            break;
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
.el-dropdown{
  .el-dropdown-link{
    color: #333;
  }
}
.caaeg{
  width: 100%;
  background: #FFFFFF;
}
.topNav{
  max-width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  height: 70px;
  width: 100%;
  padding: 0 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  .navTag{
    display: flex;
    height: 100%;
    // width: 1440px;
    min-width: 1100px;
    max-width: 1200px;
    line-height: 70px;
    .title{
      // background: url('../assets/logo@2x.png') left center no-repeat;
      background: url('../assets/logo@2x.png') center no-repeat;
      background-size: 100%;
      width: 214px;
      padding-left: 60px;
      margin-right: 60px;
      color: #004AFF;
      font-weight: bold;
    }
    .titlel{
      // background: url('../assets/logo@2x.png') left center no-repeat;
      background: url('../assets/image/logg.svg') center no-repeat;
      background-size: 100%;
      width: 214px;
      padding-left: 60px;
      margin-right: 60px;
      color: #004AFF;
      font-weight: bold;
    }

    .nav{
      display: inline-block;
      margin-right: 30px;
      cursor: pointer;
      &.active{
        color: #004AFF;
      }
    }
  }
  .navBtn{
    div{
      cursor: pointer;
    }
    ::v-deep .el-dropdown-link{
      img{
        width: 38px;
        height: 38px;
        margin-right: 10px;
      }
    }
  }
}
</style>
