const mutations = {
  setUserData(state, obj) {
    state.userData = obj.userData
  },
  setNowLinks(state, obj) {
    state.nowLinks = obj.nowLinks
  },
  setWorksType(state, obj) {
    state.worksType = obj.worksType
  },
  setAssetWorksType(state, obj) {
    state.assetWorksType = obj.assetWorksType
  },
  setLinks(state, obj) {
    state.links = obj.links
  },
  setUnreadMessage(state, obj) {
    state.unreadMessage = obj.unreadMessage
  },
  setPersonalIdentityInfo(state, obj){
    state.personalIdentityInfo = obj.personalIdentityInfo
  },
  setUserToken(state, obj){
    state.userToken = obj.userToken
  },
}

export default mutations