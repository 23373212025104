// 正式

export default {
  login_url: "https://login.service.wespace.cn/",
  node_url: "https://supernode.wespace.cn/",
  cr_url: "https://cr.service.wespace.cn/",
  coprt_tyr: 'https://cr.service.wespace.cn/',
  // box_url: "https://box.wespace.cn/",
  box_url: "https://node.wespace.cn/boxApi/",
  res_url: "https://re.service.wespace.cn/",
  asset_url: "https://as.service.wespace.cn/",
  trAsset_url: 'https://tra.service.wespace.cn/',
  file_url: 'https://files.wespace.cn',
  upload_url: "https://up.service.wespace.cn/",
  certificate_url: 'https://aut.service.wespace.cn/',
  contract_url: "https://con.service.wespace.cn/",
  newCr_url: "https://crreg.service.wespace.cn/cr/reg/",

  Real_url: "https://artwork.wespace.cn/",
  // Real_url1: "https://meeting.licenseinfo.cn/",
  Real_url1: "https://node.wespace.cn/prizeApi/",
  Book_url: "https://tra.service.wespace.cn/",
  token_url: 'https://token.wespace.cn',
  shop_url: 'https://order.service.wespace.cn',
  asset_tourl: 'https://order.service.wespace.cn/',
  asset_public: 'https://lawsuit.wespace.cn/',
  moreCr_url: "https://crreg.service.wespace.cn/",
  cms_url: "https://cms.wespace.cn/",
  assets_url: 'https://wpt.licenseinfo.cn/',
  newAssets_url: 'https://enjoymusic.wespace.cn/assets',
  newAssets_url2: 'https://api.wespace.cn/assets', //newAssets_url 和 newAssets_url2等同
  newAssets_url3: 'https://api.wespace.cn/enjoymusic-user',
  wptWespce_url: 'https://wpt.wespace.cn',
  activity_url: 'https://meeting.wespace.cn',
  pay_url: 'https://pay.wespace.cn',
  verify_url: 'https://verify.licenseinfo.cn',
  qrCode_url: 'https://assetscode.licenseinfo.cn',
  file_upload: 'https://api.wespace.cn/file-upload',//新的上传-待改为正确的域名
  cookety_url: 'https://cr.service.wespace.cn',
  verifynew_url:'https://verify.wespace.cn'
}
