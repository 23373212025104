import config from '@/config'

const state = { //要设置的全局访问的state对象
  // 全局变量
  ...config,

  // 正式环境↓

  // login_url: "https://login.service.wespace.cn/",
  // cr_url: "https://cr.service.wespace.cn/",
  // box_url: "https://box.wespace.cn/",
  // res_url: "https://re.service.wespace.cn/",
  // asset_url:"https://as.service.wespace.cn/",
  // trAsset_url:'https://tra.service.wespace.cn/',
  // file_url: 'https://files.wespace.cn',
  // upload_url:"https://up.service.wespace.cn/",
  // certificate_url:'https://aut.service.wespace.cn/',
  // contract_url:"https://con.service.wespace.cn/",

  splitSize: 5 * 1024 * 1024, // 分片大小 建议50mb
  userData: {},
  links: null,
  nowLinks: null,
  worksType: null,
  userToken: '',
  assetWorksType: null,
  personalIdentityInfo: null,
  copyrightForm: null, //权利表单的信息,
  headerLink: [{
    title: '首页',
    to: '/',
  },
  // {
  //   title: "行业热点",
  //   to: "/openCopyright"
  // },
  // {
  //   title: "方案下载",
  //   to: "/downLoad"
  // },
  // {
  //   title: "资源管理",
  //   to: "/resources"
  // },
  {
    title: "版权存证",
    to: "/preservaTion"
  },
  {
    title: "版权注册",
    to: "/copyrightList"
  },
  {
    title: "版权转让",
    to: "/transferencect"
  },
  // {
  //   title: "版权注册",
  //   to: "/copyrightList"
  // },
  {
    title: "版权授权",
    to: "/registerryList"
  },

  // {
  //   title: "诉前调解",
  //   to: "/Prelitigationmediation"
  // },
  // {
  //   title: "物权注册",
  //   to: "/newRealright"
  // },
  {
    title: "版权发行",
    to: "/assetIssue/assetIssue"
  },
  // {
  //   title: "活动管理",
  //   to: "/activity"
  // },
  // {
  //   title: "图书管理",
  //   to: "/books"
  // },
  // {
  //   title: "版权授权",
  //   to: "/contract/accreditManage"
  // },
  // {
  //   title: '交易大厅',
  //   to: '',
  // },

  //以下是新的
  // {
  //   title: '主题活动',
  //   to: '/activityList',
  // },
  // {
  //     title: '功能服务',
  //     to: '/tradService',
  // },
  // {
  //   title: '帮助中心',
  //   to: '',
  // },
  {
    title: "版权查证",
    to: "/copyright"
  },
  {
    title: "关于我们",
    to: "/about"
  },
    // {
    //     title: "版权登记",
    //     to: "/newCopyright/copyList"
    // },
  ],

  unreadMessage: "0", //未读消息数量
  pathData: [
    '/', '/login', '/infoPublicity', '/infoPublicity/details',
    '/shopDetails', '/activityList', '/shopMall', '/about',
    '/newsDetails', '/register', '/copyright', '/shopDigitalDetails', '/tradService', '/messageBox', '/Submission', '/copyrightList', '/copyrightRegistration',
    '/transferencect', '/transferencecttration', '/transferenceGoods', '/registerry', '/registerryList','/preservaTion','/certificate'

  ],
  routeNav: []
};

export default state