import axios from 'axios'
import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
import router from '../router/index'
import store from '../store/index';
// 创建axios的对象
const instance = axios.create({
    // baseURL: "http://117.107.213.242:10089/",  //测试域名
    baseURL: "https://node.wespace.cn/",  //正式域名
    // baseURL: "https://supernode.wespace.cn/",  //正式域名
    // baseURL: "https://3test.licenseinfo.cn/",  //验收测试域名
    timeout: 60000
})
const IGNORED_INTERFACES = ['/userApi/user/login', '/userApi/user/v2/update/password', '/userApi/user/login/register'];
// 请求拦截
// 所有的网络请求都会走这个方法,可以在请求添加自定义内容
instance.interceptors.request.use(
    config => {
        const ignored = IGNORED_INTERFACES.some(url => config.url.startsWith(url));
        if(ignored === true){
            var str = 'wespace:wespace-secret';
            var encoder = new TextEncoder();
            var data = encoder.encode(str);
            var encoded = btoa(String.fromCharCode.apply(null, data));
            config.headers['Authorization'] = ('Basic ' + encoded)
        }else if(ignored === false){
            config.headers['Authorization'] = ('Bearer ' + sessionStorage.getItem('usn-token'))
        }
        return config
    },
    error => {
        // do something with request error （在响应出现error时做些什么）
        console.log("111",error) // for debug
        return Promise.reject(error)
    }
)
// 请求拦截
// 所有的网络请求都会走这个方法,可以在请求添加自定义内容
// instance.interceptors.request.use(
//     config => {
//         // do something before request is sent （在请求发送前做些什么）
//         // config.headers['nodeToken'] = '2c91edc611e437671fd47fd16ef386ccc1145382f5fc59e977dfacdf2209d114'
//         // return config
//         // 注释了
//         // if (sessionStorage.getItem('vue_admin_lottery_token')) {
//         // let each request carry token （让每个请求携带令牌）
//         // ['X-Token'] is a custom headers key （ ['X-Token'] 是自定义的header key ，请根据实际情况修改）
//         // please modify it according to the actual situation
//         // config.headers['X-Token'] = getToken()
//         // config.headers['Authorization'] = ('Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6WyJzaGFuZ2ZhbmciXSwiZXhwIjoxOTQwMTQ4NjIxLCJqdGkiOiI1NmJjYTYxMS0xNmM0LTQwNmItODBlYi01MjI2MTk2YWJlYzkiLCJjbGllbnRfaWQiOiJzaGFuZ2ZhbmcifQ.m24FTz8Z-c85LRKJgpB6CjZ--8G36KQeQF-Ez03IycOkLqGyJnXz81smDLSrWygGX3cd1xDHwFd4hq03z52X8f_u2j0ReWVcCLVG6nLnhbhrxe0FtOkbNsyuviGSwm9-9pm0ZbaWlcAzSuWPiGPMb21eFPvETAFTardWUz3E1O8')
//         // }
//         if (sessionStorage.getItem('usn-token')) {
//             config.headers['Authorization'] = ('Bearer ' + sessionStorage.getItem('usn-token'))
//         }
//         else {
//             // config.headers['Authorization'] = 'Basic d2VzcGFjZTp3ZXNwYWNlLXNlY3JldA==' // node
//             // config.headers['Authorization'] = 'Basic c3VwZXJub2RlOnN1cGVybm9kZS1zZWNyZXQ=' // supernode
//             // config.headers['Authorization'] = 'Basic anV6aGVuOmp1emhlbi1zZWNyZXQ=' // 矩阵
//             var str = 'wespace:wespace-secret';
//             var encoder = new TextEncoder();
//             var data = encoder.encode(str);
//             var encoded = btoa(String.fromCharCode.apply(null, data));
//             console.log("11111",encoded);
//             config.headers['Authorization'] = ('Basic ' + encoded)
//         }
//         return config
//     },
//     error => {
//         // do something with request error （在响应出现error时做些什么）
//         console.log(error) // for debug
//         return Promise.reject(error)
//     }
// )

// 响应拦截
// 此处可以根据服务器返回的状态码做相应的数据
instance.interceptors.response.use(
    // function (response) {
    //     const res = response
    //     if (res.status === 500) {
    //         MessageBox.alert('系统未登录，请重新登录', '错误', {
    //             confirmButtonText: '确定',
    //             type: 'error'
    //         }).then(() => {
    //             store.dispatch('FedLogOut').then(() => {
    //                 location.reload()
    //             })
    //         })
    //         return Promise.reject('error')
    //     } else if (res.errno === 502) {
    //         MessageBox.alert('系统内部错误，请联系管理员维护', '错误', {
    //             confirmButtonText: '确定',
    //             type: 'error'
    //         })
    //         return Promise.reject('error')
    //     }
    // },
    // function (err) {
    //     return Promise.request(err)
    // }
    /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   * 如果你需要在返回的data中得到接口的headers，status等信息，请原封不动将response返回 return response => response
  */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     * 通过自定义的的 response HTTP Status Code 来确定请求状态
     * tip: 在这个模板中默认的mock接口返回的数据中，code:20000 表示接口状态正确正常
     */
    response => {
        const res = response.data
        switch (res.code) {
            case 400:
                Message({
                    message: error.message,
                    type: 'error',
                    duration: 1500,
                    customClass: 'element-error-message-zindex'
                })
                break
            case 401:
                if (window.location.pathname !== '/login') {
                  store.commit({
                    type: "setUserToken",
                    userToken: ""
                  })
                  store.commit({
                    type: "setUserData",
                    userData: ""
                  })
                  store.commit({
                    type: "setUnreadMessage",
                    unreadMessage: "0"
                  })
                  sessionStorage.removeItem('vuex')
                  sessionStorage.removeItem('usnInfo')
                  // sessionStorage.removeItem('accessToken');//清除本地
                  sessionStorage.removeItem('usn-token');//清除本地
                    router.push({
                        name: 'login',
                        query: {
                            redirect:window.location.pathname
                        }
                    })
                  Message({
                    message: '权限不足，请重新登录',
                    type: 'error',
                    duration: 1500,
                    customClass: 'element-error-message-zindex'
                  })
                }
                // window.location.reload()
                break
            case 405:
                Message({
                    message: 'http请求方式有误',
                    type: 'error',
                    duration: 1500,
                    customClass: 'element-error-message-zindex'
                })
                break
            case 500:
                Message({
                    message: '服务器出了点小差，请稍后再试',
                    type: 'error',
                    duration: 1500,
                    customClass: 'element-error-message-zindex'
                })
                break
            case 501:
                Message({
                    message: '服务器不支持当前请求所需要的某个功能',
                    type: 'error',
                    duration: 1500,
                    customClass: 'element-error-message-zindex'
                })
                break
            }
        return res
    },
    error => {
        if (error.response.status === 401) {
          // 登录失败，处理401错误
          store.commit({
            type: "setUserToken",
            userToken: ""
          })
          store.commit({
            type: "setUserData",
            userData: ""
          })
          store.commit({
            type: "setUnreadMessage",
            unreadMessage: "0"
          })
          sessionStorage.removeItem('vuex')
          sessionStorage.removeItem('usnInfo')
          // sessionStorage.removeItem('accessToken');//清除本地
          sessionStorage.removeItem('usn-token');//清除本地
        //   router.go(0)
          router.replace({
            name: 'login'
            // query: {redirect: router.currentRoute.fullPath}
            //携带路由从什么地方失效的
          })
        }
        console.log('err' + error) // for debug
        Message({
            message: '权限不足，请重新登录',
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

// 封装get和post请求
export function get (url, params) {
    return instance.get(url, { params })
}

export function post (url, data) {
    return instance.post(url, data)
}

export default instance;
